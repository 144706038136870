<template>
  <b-card title="💣💣💣 Você não tem autorização para estar aqui! 💣💣💣" class="text-center">
    <br>
    <b-card-text>Caia fora, antes que vc fique marcado nos logs como um suspeito</b-card-text>
  </b-card>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
  },
}
</script>

<style>

</style>
